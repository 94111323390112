/**
 * This file is run after everything else
 */

window.googleMapsApiKey = 'AIzaSyDJZARWuoPkQ9n1cMtCM82Neh9wfnJXqQE';

function runMain(F, W) {
    var $maps;

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runMain(F, W);
        }, 10);
        return;
    }

    // Create a global model
    var GlobalModelConstructor = F.model.make({
        triggerMobileMenuClose: 'int'
    });
    W.FabGlobalModel = new GlobalModelConstructor();

    // Construct MobileMenu controller
    F.controller.construct('MobileMenu', {
        el: '.js-site-header'
    });

    // Construct SubMenu controllers
    $('.js-sub-nav').each(function() {
        F.controller.construct('SubNav', {
            el: this
        });
    });

    // Construct AccordionToTabs controllers
    $('.js-accordion-to-tabs').each(function() {
        F.controller.construct('AccordionToTabs', {
            el: this
        });
    });

    // Construct split scrolls
    $('.js-split-scroll').each(function() {
        F.controller.construct('SplitScroll', {
            el: this
        });
    });

    // Construct mobile accordion
    $('.js-mobile-content-accordion').each(function() {
        F.controller.construct('MobileContentAccordion', {
            el: this
        });
    });

    // Check for map
    $maps = $('.js-location-map');

    // If there are maps
    if ($maps.length) {
        // Load the Google Maps api
        F.assets.load({
            root: 'https://maps.googleapis.com/',
            js: '/maps/api/js?key=' + window.googleMapsApiKey,
            success: function() {
                // Construct controllers
                $maps.each(function() {
                    F.controller.construct('Map', {
                        el: this
                    });
                });
            }
        });
    }

    // Get anchor links
    $('a[href^="#"]').add('.js-anchor-link').each(function() {
        F.controller.construct('AnchorLink', {
            el: this
        });
    });

    // Check for incoming anchor
    if (window.anchor) {
        F.controller.construct('InitialAnchor');
    }

    // Instantiate month day selectors
    $('.js-month-day-selectors').each(function() {
        F.controller.construct('MonthDaySelector', {
            el: this
        });
    });

    // Instantiate nav order arrangement controller
    F.controller.construct('NavOrderArrangement', {
        $upperNav: $('.js-site-nav-upper'),
        $lowerNav: $('.js-site-nav-lower')
    });

    // Check for triggers to close mobile menu on click
    $('.js-close-mobile-menu-on-click').on('click', function() {
        W.FabGlobalModel.set(
            'triggerMobileMenuClose',
            W.FabGlobalModel.get('triggerMobileMenuClose') + 1
        );
    });

    // Run read more links
    $('.js-read-more').each(function() {
        F.controller.construct('ReadMore', {
            el: this
        });
    });

    // This makes iOS respond to clicks on menu with
    // hover effects right away. Why this works? ¯\_(ツ)_/¯
    $('a').on('click touchend', function() {
        // var el = $(this);
        // var link = el.attr('href');
        // console.log(link);
        // window.location = link;
    });

    // Initialize date pickers
    $('.js-date-picker').each(function() {
        F.controller.construct('DatePicker', {
            el: this
        });
    });

    // Initialize form validation
    $('.js-form-validate').each(function() {
        F.controller.construct('FormValidate', {
            el: this
        });
    });
}

runMain(window.FAB, window);
