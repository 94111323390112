function runFormValidate(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runFormValidate(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('FormValidate', {
        $inputs: null,
        validationErrors: {},
        validationTimers: [],

        events: {
            submit: function(e) {
                // Save a reference to the controller
                var self = this;

                // Run validation
                self.runValidation();

                // If there are no validation errors, we can proceed with submission
                if (! Object.keys(self.validationErrors).length) {
                    return;
                }

                // Prevent form from submitting
                e.preventDefault();

                // Set errors
                self.setErrors();
            }
        },

        init: function() {
            // Save a reference to the controller
            var self = this;

            // Get all inputs
            self.$inputs = self.$el.find(':input').not('[type="submit"]');

            // Validate inputs on change and keyup
            self.$inputs.each(function() {
                self.watchInputValidation($(this));
            });

            // Prevent default validation
            self.$el.attr('novalidate', true);
        },

        watchInputValidation: function($input) {
            // Save a reference to the controller
            var self = this;
            var name = $input.attr('name');

            function validation() {
                if (self.validationTimers[name]) {
                    clearTimeout(self.validationTimers[name]);
                }

                self.validationTimers[name] = setTimeout(function() {
                    var errors = self.validateInput($input);

                    self.unsetInputError($input);

                    if (errors !== true) {
                        self.setInputError(errors);
                    }
                }, 500);
            }

            $input.on('change', function() {
                validation();
            });

            $input.on('keyup', function() {
                if (! $input.hasClass('form-validation-error')) {
                    return;
                }

                validation();
            });
        },

        runValidation: function() {
            // Save a reference to the controller
            var self = this;

            // Reset validation
            self.resetValidation();

            // Iterate through inputs
            self.$inputs.each(function() {
                var $input = $(this);

                var validation = self.validateInput($input);

                if (validation !== true) {
                    self.validationErrors[$input.attr('name')] = validation;
                }
            });
        },

        resetValidation: function() {
            // Save a reference to the controller
            var self = this;

            // Reset validation object
            self.validationErrors = {};

            // Remove all validation errors from the DOM
            self.$el.find('.form-validation-error-list').remove();

            // Remove error class from inputs
            self.$inputs.removeClass('form-validation-error');
        },

        validateInput: function($input) {
            // Save a reference to the controller
            var self = this;
            var val = $input.val();
            var errors = [];

            if ($input.attr('required') && ! val) {
                errors.push('This field is required');
            } else if ($input.attr('type') === 'email' &&
                ! self.validateEmail(val)
            ) {
                errors.push('A valid email address is required');
            }

            if (! errors.length) {
                return true;
            }

            return {
                $input: $input,
                errors: errors
            };
        },

        setErrors: function() {
            // Save a reference to the controller
            var self = this;
            var errors = self.validationErrors;
            var errorKey;

            for (errorKey in errors) {
                if (errors.hasOwnProperty(errorKey)) {
                    self.setInputError(errors[errorKey]);
                }
            }
        },

        setInputError: function(errorObj) {
            var error;
            var $errorList;

            errorObj.$input.addClass('form-validation-error');

            $errorList = $(
                '<ul class="form-validation-error-list"></ul>'
            );

            for (error in errorObj.errors) {
                if (errorObj.errors.hasOwnProperty(error)) {
                    $errorList.append(
                        '<li>' + errorObj.errors[error] + '</li>'
                    );
                }
            }

            $errorList.insertAfter(errorObj.$input);
        },

        unsetInputError: function($input) {
            $input.removeClass('form-validation-error');

            $input.siblings('.form-validation-error-list').remove();
        },

        validateEmail: function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    });
}

runFormValidate(window.FAB, window);
