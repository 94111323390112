function runMobileMenu(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runMobileMenu(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('MobileMenu', {
        /**
         * Modeled data for this controller
         * @var {Object} model
         */
        model: {
            mobileMenuIsActive: 'bool',
            mode: 'string'
        },

        /**
         * Events to automatically be bound
         * @var {Object} events
         */
        events: {
            'click .js-mobile-menu-activator': function(e) {
                // Save a reference to the controller
                var self = this;

                // Prevent default
                e.preventDefault();

                // Set the menu active state
                self.model.set(
                    'mobileMenuIsActive',
                    ! self.model.get('mobileMenuIsActive')
                );
            }
        },

        /**
         * Init runs on controller construction
         */
        init: function() {
            // Save a reference to the controller
            var self = this;

            // Watch for change to mobileMenuIsActive model property
            self.model.onChange('mobileMenuIsActive', function() {
                self.openCloseLogic();
            });

            $(W).on('resize', function() {
                if (W.innerWidth >= 1100) {
                    self.model.set('mobileMenuIsActive', false);
                }
            });

            W.FabGlobalModel.onChange('triggerMobileMenuClose', function() {
                self.model.set('mobileMenuIsActive', false);
            });
        },

        /**
         * Open and close menu logic
         */
        openCloseLogic: function() {
            // Save a reference to the controller
            var self = this;

            // Get the menu state
            var mobileMenuIsActive = self.model.get('mobileMenuIsActive');

            if (mobileMenuIsActive) {
                self.openMobileMenu();
            } else {
                self.closeMobileMenu();
            }
        },

        /**
         * Open mobile menu
         */
        openMobileMenu: function() {
            // Add the open class
            $('[data-menu-open-class]').each(function() {
                var $el = $(this);
                $el.addClass($el.data('menuOpenClass'));
            });
        },

        /**
         * Close mobile menu
         */
        closeMobileMenu: function() {
            // Remove the open class
            $('[data-menu-open-class]').each(function() {
                var $el = $(this);
                $el.removeClass($el.data('menuOpenClass'));
            });
        }
    });
}

runMobileMenu(window.FAB, window);
