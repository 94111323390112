function runAnchorLink(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runAnchorLink(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('AnchorLink', {
        /** @var {Integer} animationTime */
        animationTime: 600,

        /** @var {Jquery} $siteHeader */
        $siteHeader: $(),

        /** @var {Jquery} $htmlBody */
        $htmlBody: $(),

        /** @var {String} href */
        href: '',

        /** @var {String} id */
        id: '',

        /** @var {Jquery} $scrollTo */
        $scrollTo: $(),

        /**
         * Initialize
         */
        init: function() {
            // Save a reference to the controller
            var self = this;

            // Split the href
            var hrefSplit;

            // Set the site header
            self.$siteHeader = $('.js-site-header');

            // Get the HTML body
            self.$htmlBody = $('html, body');

            // Set the href
            self.href = self.$el.attr('href');

            // Set the ID
            hrefSplit = self.href.split('#');
            self.id = hrefSplit[hrefSplit.length - 1];

            // Set the href again
            self.href = '#' + hrefSplit[hrefSplit.length - 1];

            // Set $scrollTo
            self.$scrollTo = $('#' + self.id);
        },

        /**
         * Events to automatically be bound
         * @var {Object} events
         */
        events: {
            'click': function(e) {
                // Save a reference to the controller
                var self = this;

                // Prevent the default action
                e.preventDefault();

                // Run scroll
                self.runScroll();
            }
        },

        /**
         * Run the scroll
         */
        runScroll: function() {
            // Save a reference to the controller
            var self = this;

            // Callback has fired
            var callbackHasFired = false;

            // If there is no ID or no element we should bail out
            if (! self.id || ! self.$scrollTo.length) {
                return;
            }

            // Remove the ID from the element so we don't have page jumping
            self.$scrollTo.attr('id', null);

            // Replace history state
            W.history.replaceState(
                {},
                document.title,
                W.location.origin + W.location.pathname + self.href
            );

            // Scroll to
            self.$htmlBody.animate({
                scrollTop: self.$scrollTo.offset().top - self.$siteHeader.innerHeight()
            }, self.animationTime, function() {
                // Make sure this callback has not fired
                if (callbackHasFired) {
                    return;
                }

                // The callback has fired
                callbackHasFired = true;

                // Set the ID back on the element
                self.$scrollTo.attr('id', self.id);
            });
        }
    });
}

runAnchorLink(window.FAB, window);
