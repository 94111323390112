function runDatePicker(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runDatePicker(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('DatePicker', {
        init: function() {
            // Save a reference to the controller
            var self = this;

            // Create a current date object
            var date = new Date();

            // Create a new date picker
            new W.Pikaday({
                field: self.el,
                maxDate: new Date(),
                yearRange: [date.getFullYear() - 120, date.getFullYear()],
                toString: function(inComingDate) {
                    // Get applicable date vars
                    var month = inComingDate.getMonth() + 1;
                    var day = inComingDate.getDate();
                    var year = inComingDate.getFullYear();

                    // Include leading zeros if applicable
                    if (month < 10) {
                        month = '0' + month.toString();
                    }

                    if (day < 10) {
                        day = '0' + day.toString();
                    }

                    // Cast to string
                    month = month.toString();
                    day = day.toString();
                    year = year.toString();

                    // Return the formatted date
                    return month + '/' + day + '/' + year;
                }
            });
        }
    });
}

runDatePicker(window.FAB, window);
