function runMonthDaySelector(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runMonthDaySelector(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('MonthDaySelector', {
        /** {Object} numDaysMap */
        numDaysMap: {
            january: 31,
            february: 28,
            march: 31,
            april: 30,
            may: 31,
            june: 30,
            july: 31,
            august: 31,
            september: 30,
            october: 31,
            november: 30,
            december: 31
        },

        /** {Object} numDaysMap */
        numToMonth: {
            1: 'january',
            2: 'february',
            3: 'march',
            4: 'april',
            5: 'may',
            6: 'june',
            7: 'july',
            8: 'august',
            9: 'september',
            10: 'october',
            11: 'november',
            12: 'december'
        },

        /**
         * Modeled data for this controller
         * @var {Object} model
         */
        model: {
            firstMonthChange: 'bool'
        },

        /** {Jquery} $daySelectDisabled */
        $daySelectDisabled: $(),

        /** {String} originalDisabledText */
        originalDisabledText: '',

        /** {Jquery} $daySelector */
        $daySelector: $(),

        /** {Jquery} $monthSelector */
        $monthSelector: $(),

        /**
         * Initialize
         */
        init: function() {
            // Save a reference to the controller
            var self = this;

            // Create a date object
            var dateObj = new Date();

            // Get the current month
            var month = dateObj.getUTCMonth();

            // Get the current day
            var day = dateObj.getUTCDate();

            // Set the month selector
            self.$monthSelector = self.$el.find('.js-month-selector');

            // Get the disabled select
            self.$daySelectDisabled = self.$el.find('.js-day-select-disabled')
                .clone();

            // Set original disabled text
            self.originalDisabledText = self.$daySelectDisabled.html();

            // Set new text
            self.$daySelectDisabled.html('Select Month First&hellip;');

            // Get the day selector
            self.$daySelector = self.$el.find('.js-day-selector');

            // Empty the day selector and put only the disabled select
            self.$daySelector.html(self.$daySelectDisabled);

            /**
             * Set to current month and day
             */

            // Set the month
            setTimeout(function() {
                self.$monthSelector.val(self.numToMonth[month]).trigger('change');
            }, 800);

            // Watch for change to firstMonthChange property
            self.model.onChange('firstMonthChange', function() {
                // Make sure day value is right if less than 10 with leading zero
                if (day < 10) {
                    day = '0' + day.toString();
                }

                // Set the day
                self.$daySelector.val(day).trigger('change');
            });
        },

        /**
         * Events to automatically be bound
         * @var {Object} events
         */
        events: {
            'change .js-month-selector': function() {
                // Save a reference to the controller
                var self = this;

                // Run the change function
                self.runChange();
            }
        },

        /**
         * Run change event
         */
        runChange: function() {
            // Save a reference to the controller
            var self = this;

            // Get current val
            var val = self.$monthSelector.val();

            // Start html
            var html = '<option value="" selected disabled>';
            html += self.originalDisabledText;
            html += '</option>';

            // Build the day selects
            for (var i = 1; i <= self.numDaysMap[val]; i++) {
                html += '<option value="';

                if (i < 10) {
                    html += '0';
                }

                html += i + '">';

                if (i < 10) {
                    html += '0';
                }

                html += i + '</option>';
            }

            // Set the HTML
            self.$daySelector.html(html);

            // Set the first change model
            self.model.set('firstMonthChange', true);
        }
    });
}

runMonthDaySelector(window.FAB, window);
