function runSubNav(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runSubNav(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('SubNav', {
        /** @var {Integer} mobileMenuAnimationTime */
        menuAnimationTime: 150,

        /** @var {Integer} mobileMenuAnimationTime */
        desktopModeSwitch: 1100,

        /** @var {Integer} mouseLeaveTimer */
        mouseLeaveTimer: 0,

        /**
         * Modeled data for this controller
         * @var {Object} model
         */
        model: {
            subNavIsActive: 'bool',
            mode: 'string'
        },

        /**
         * Events to automatically be bound
         * @var {Object} events
         */
        events: {
            click: function() {
                // Save a reference to the controller
                var self = this;

                // Prevent default
                // e.preventDefault();

                // Check if we're in desktop mode and stop if so
                if (W.innerWidth >= self.desktopModeSwitch) {
                    return;
                }

                // Set the menu active state
                self.model.set(
                    'subNavIsActive',
                    ! self.model.get('subNavIsActive')
                );
            },
            mouseenter: function() {
                // Save a reference to the controller
                var self = this;

                // Check if we're in mobile mode and stop if so
                if (W.innerWidth < self.desktopModeSwitch) {
                    return;
                }

                // Cancel any mouseleave event
                clearTimeout(self.mouseLeaveTimer);

                // Set the menu active state
                self.model.set('subNavIsActive', true);
            },
            mouseleave: function() {
                // Save a reference to the controller
                var self = this;

                // Check if we're in mobile mode and stop if so
                if (W.innerWidth < self.desktopModeSwitch) {
                    return;
                }

                // After a short timeout, close the menu
                self.mouseLeaveTimer = setTimeout(function() {
                    // Set the menu active state
                    self.model.set('subNavIsActive', false);
                }, 400);
            }
        },

        /**
         * Init runs on controller construction
         */
        init: function() {
            // Save a reference to the controller
            var self = this;

            // Watch for change to mobileMenuIsActive model property
            self.model.onChange('subNavIsActive', function() {
                self.openCloseLogic();
            });
        },

        /**
         * Open and close menu logic
         */
        openCloseLogic: function() {
            // Save a reference to the controller
            var self = this;

            // Get the menu state
            var mobileMenuIsActive = self.model.get('subNavIsActive');

            if (mobileMenuIsActive) {
                self.openMobileMenu();
            } else {
                self.closeMobileMenu();
            }
        },

        /**
         * Open mobile menu
         */
        openMobileMenu: function() {
            // Save a reference to the controller
            var self = this;

            // Open the menu
            self.$el.addClass(self.$el.data('subNavOpenClass'));
            self.$el.find('.js-sub-nav-list').slideDown(self.menuAnimationTime);
        },

        /**
         * Close mobile menu
         */
        closeMobileMenu: function() {
            // Save a reference to the controller
            var self = this;

            // Open the menu
            self.$el.removeClass(self.$el.data('subNavOpenClass'));
            self.$el.find('.js-sub-nav-list').slideUp(self.menuAnimationTime);
        }
    });
}

runSubNav(window.FAB, window);
