function runInitialAnchor(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runInitialAnchor(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('InitialAnchor', {
        /** @var {Integer} animationTime */
        animationTime: 600,

        init: function() {
            // Save a reference to the controller
            var self = this;

            // Set the site header
            var $siteHeader = $('.js-site-header');

            // Get the HTML body
            var $htmlBody = $('html, body');

            // Set the href
            var href = window.anchor;

            // Set the ID
            var id = href.split('#')[1];

            // Set $scrollTo
            var $scrollTo = $('#' + id);

            // Callback has fired
            var callbackHasFired = false;

            // If there is no element we should bail out
            if (! $scrollTo.length) {
                return;
            }

            // Remove the ID from the element so we don't have page jumping
            $scrollTo.attr('id', null);

            // Replace history state
            W.history.replaceState(
                {},
                document.title,
                W.location.origin + W.location.pathname + href
            );

            setTimeout(function() {
                // Scroll to
                $htmlBody.animate({
                    scrollTop: $scrollTo.offset().top - $siteHeader.innerHeight()
                }, self.animationTime, function() {
                    // Make sure this callback has not fired
                    if (callbackHasFired) {
                        return;
                    }

                    // The callback has fired
                    callbackHasFired = true;

                    // Set the ID back on the element
                    $scrollTo.attr('id', id);
                });
            }, 800);
        }
    });
}

runInitialAnchor(window.FAB, window);
