function runAccordionToTabs(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runAccordionToTabs(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('AccordionToTabs', {
        /** @var {Integer} mobileAnimationTime */
        mobileAnimationTime: 150,

        /** @var {Integer} desktopSwitchWidth */
        desktopSwitchWidth: 800,

        /** @var {Jquery} $items */
        $items: $(),

        /** @var {Jquery} $desktopTabs */
        $tabs: $(),

        /** @var {Jquery} $siteHeader */
        $siteHeader: $(),

        /** @var {Jquery} $htmlBody */
        $htmlBody: $(),

        /**
         * Modeled data for this controller
         * @var {Object} model
         */
        model: {
            activeIndex: 'int'
        },

        /**
         * Events to automatically be bound
         * @var {Object} events
         */
        events: {
            'click .js-tab-switcher': function(e) {
                // Save a reference to the controller
                var self = this;

                // Get the element
                var $el = $(e.currentTarget);

                // Set the active index
                self.model.set('activeIndex', $el.data('index'));
            }
        },

        /**
         * Init runs on controller construction
         */
        init: function() {
            // Save a reference to the controller
            var self = this;

            // Get the HTML body
            self.$htmlBody = $('html, body');

            // Set the site header
            self.$siteHeader = $('.js-site-header');

            // Index items
            self.indexItems();

            // Watch for changes to the activeIndex
            self.model.onChange('activeIndex', function(index) {
                self.activateTabAtIndex(index);
            });
        },

        /**
         * Index items
         */
        indexItems: function() {
            // Save a reference to the controller
            var self = this;

            // Set index variable
            var index = 0;

            // Set the active index
            self.model.set('activeIndex', 0);

            // Set items
            self.$items = self.$el.find('.js-tab-item');

            // Set tabs
            self.$tabs = self.$el.find('.js-tabs .js-tab-switcher');

            // Iterate through items and set index data
            self.$items.each(function() {
                var $el = $(this);

                $el.attr('data-index', index);

                $el.find('.js-tab-switcher').attr('data-index', index);

                index++;
            });

            // Reset index
            index = 0;

            // Iterate through tabs and set them
            self.$tabs.each(function() {
                var $el = $(this);

                $el.attr('data-index', index);

                index++;
            });
        },

        /**
         * Activate tab at index
         * @param {number} index
         */
        activateTabAtIndex: function(index) {
            // Save a reference to the controller
            var self = this;

            // Item
            var $item = self.$items.eq(index);

            // Tab content
            var $tabContent = $item.find('.js-tab-content');

            // Tab
            var $tab = self.$tabs.eq(index);

            // AnimationTime
            var animTime = self.mobileAnimationTime;

            // Deactivate tabs
            self.$tabs.each(function() {
                // Cast the element
                var $el = $(this);

                // Check if the tab is active
                if (! $el.hasClass($el.data('activeClass'))) {
                    return;
                }

                // Remove the active class
                $el.removeClass($el.data('activeClass'));
            });

            // Close existing items
            self.$items.each(function() {
                // Cast the element
                var $el = $(this);

                // Check if the element is active
                if (! $el.hasClass($el.data('activeClass'))) {
                    return;
                }

                // Deactivate the element
                if (W.innerWidth < self.desktopSwitchWidth) {
                    $el.find('.js-tab-content').slideUp(
                        animTime,
                        'swing',
                        function() {
                            $el.removeClass($el.data('activeClass'));
                        }
                    );

                    // Scroll to
                    self.$htmlBody.animate({
                        scrollTop: self.$el.offset().top - self.$siteHeader.innerHeight()
                    }, animTime);
                } else {
                    $el.find('.js-tab-content').hide();
                    $el.removeClass($el.data('activeClass'));
                }
            });

            // Activate element
            if (W.innerWidth < self.desktopSwitchWidth) {
                $tabContent.slideDown(animTime, 'swing', function() {
                    $item.addClass($item.data('activeClass'));
                    $tab.addClass($tab.data('activeClass'));
                });
            } else {
                $tabContent.show();
                $item.addClass($item.data('activeClass'));
                $tab.addClass($tab.data('activeClass'));
            }
        }
    });
}

runAccordionToTabs(window.FAB, window);
