function runNavOrderArragement(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runNavOrderArragement(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('NavOrderArrangement', {
        /** @var {Integer} mobileMenuAnimationTime */
        desktopModeSwitch: 1100,

        /**
         * Modeled data for this controller
         * @var {Object} model
         */
        model: {
            isMobile: 'bool'
        },

        /**
         * Init runs on controller construction
         */
        init: function() {
            // Save a reference to the controller
            var self = this;

            // Get window as jquery
            var $W = $(W);

            // Set initial mode
            self.model.set('isMobile', false);

            // Watch for changes to the window size
            $W.on('resize', function() {
                self.model.set(
                    'isMobile',
                    $W.innerWidth() < self.desktopModeSwitch
                );
            });

            // Watch for model changes
            self.model.onChange('isMobile', function(val) {
                if (val) {
                    self.setMobile();
                } else {
                    self.setDesktop();
                }
            });

            // Set initial state
            self.model.set(
                'isMobile',
                $W.innerWidth() < self.desktopModeSwitch
            );
        },

        /**
         * Set mobile mode
         */
        setMobile: function() {
            // Save a reference to the controller
            var self = this;

            // Move the upper nav to the bottom
            self.$upperNav.insertAfter(self.$lowerNav);
        },

        /**
         * Set desktop mode
         */
        setDesktop: function() {
            // Save a reference to the controller
            var self = this;

            // Move the upper nav back to the top
            self.$lowerNav.insertAfter(self.$upperNav);
        }
    });
}

runNavOrderArragement(window.FAB, window);
