function runSplitScroll(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runSplitScroll(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('SplitScroll', {
        $document: $(document),
        $siteHeader: $('.js-site-header'),
        $panel: $(),
        $panelInner: $(),
        stickyClass: '',
        atEndClass: '',

        model: {
            panelIsSticky: 'bool',
            panelIsAtEnd: 'bool',
            panel: 'string'
        },

        events: {
            'click .js-mobile-expand': function(e) {
                // Save a reference to the controller
                var self = this;

                // Get the content panel
                var $contentPanel = self.$el.find(
                    '.js-split-scroll-right-panel-inner'
                );

                // Get this element
                var $el = $(e.currentTarget);

                // Add open class to content panel
                $contentPanel.addClass($contentPanel.data('isOpenClass'));

                // Hide the element
                $el.hide();
            }
        },

        init: function() {
            // Save a reference to the controller
            var self = this;

            // Get window jquery
            var $W = $(W);

            // Get left panel
            var $leftPanel = self.$el.find('.js-split-scroll-left-panel');
            var $leftPanelInner = $leftPanel.find(
                '.js-split-scroll-left-panel-inner'
            );

            // Get the right panel
            var $rightPanel = self.$el.find('.js-split-scroll-right-panel');
            var $rightPanelInner = $rightPanel.find(
                '.js-split-scroll-right-panel-inner'
            );

            // Set initial model values
            self.model.set('panelIsSticky', false);
            self.model.set('panelIsAtEnd', false);

            // Set which panel
            self.model.set(
                'panel',
                $leftPanelInner.innerHeight() >
                    $rightPanelInner.innerHeight() ?
                    'right' :
                    'left'
            );

            // Set classes
            if (self.model.get('panel') === 'left') {
                self.$panel = $leftPanel;
                self.$panelInner = $leftPanelInner;
                self.stickyClass = $leftPanel.data('stickyClass');
                self.atEndClass = $leftPanel.data('atEndClass');
            } else {
                self.$panel = $rightPanel;
                self.$panelInner = $rightPanelInner;
                self.stickyClass = $rightPanel.data('stickyClass');
                self.atEndClass = $rightPanel.data('atEndClass');
            }

            // Watch for window scroll
            $W.on('scroll', function() {
                // Run the window scroll function
                self.windowScroll();
            });

            // Watch for window resize
            $W.on('resize', function() {
                // Run the window scroll function
                self.windowScroll();
            });

            // Watch for changes to model panelIsSticky
            self.model.onChange('panelIsSticky', function(val) {
                if (val) {
                    self.setPanelIsSticky();
                } else {
                    self.setPanelIsNotStick();
                }
            });

            // Watch for changes to model panelIsAtEnd
            self.model.onChange('panelIsAtEnd', function(val) {
                if (val) {
                    self.setPanelIsAtEnd();
                } else {
                    self.setPanelIsNotAtEnd();
                }
            });

            // Run the window scroll function for initial in case window
            // is scrolled on start
            self.windowScroll();
        },

        docScrollTop: function() {
            var self = this;
            return self.$document.scrollTop() + self.$siteHeader.height();
        },

        panelIsTop: function() {
            var self = this;
            return self.docScrollTop() >= self.$panel.offset().top;
        },

        panelIsEnd: function() {
            var self = this;
            var innerEndPoint = self.$panelInner.outerHeight();
            var outerEndScroll = (
                    self.$panel.offset().top + self.$panel.height()
                ) - self.docScrollTop() + 3;

            return innerEndPoint >= outerEndScroll;
        },

        windowScroll: function() {
            // Save a reference to the controller
            var self = this;

            // Set model properties
            self.model.set('panelIsSticky', self.panelIsTop());
            self.model.set('panelIsAtEnd', self.panelIsEnd());
        },

        setPanelIsSticky: function() {
            // Save a reference to the controller
            var self = this;

            // Set the class
            self.$panel.addClass(self.stickyClass);
        },

        setPanelIsNotStick: function() {
            // Save a reference to the controller
            var self = this;

            // Remove the class
            self.$panel.removeClass(self.stickyClass);
        },

        setPanelIsAtEnd: function() {
            // Save a reference to the controller
            var self = this;

            // Remove the class
            self.$panel.addClass(self.atEndClass);
        },

        setPanelIsNotAtEnd: function() {
            // Save a reference to the controller
            var self = this;

            // Remove the class
            self.$panel.removeClass(self.atEndClass);
        }
    });
}

runSplitScroll(window.FAB, window);
