function runReadMore(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runReadMore(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('ReadMore', {
        $teaser: null,
        $full: null,

        /**
         * Init runs on controller construction
         */
        init: function() {
            // Save a reference to the controller
            var self = this;

            // Get teaser
            self.$teaser = self.$el.find('.js-read-more-teaser');

            // Get full
            self.$full = self.$el.find('.js-read-more-full');
        },

        /**
         * Events to automatically be bound
         * @var {Object} events
         */
        events: {
            'click .js-read-more-expand': function() {
                // Save a reference to the controller
                var self = this;

                // Get the element
                self.expand();
            },
            'click .js-read-more-collapse': function() {
                // Save a reference to the controller
                var self = this;

                // Get the element
                self.collapse();
            }
        },

        /**
         * Expand
         */
        expand: function() {
            // Save a reference to the controller
            var self = this;

            // Hide the teaser
            self.$teaser.hide();

            // Show the full text
            self.$full.show();
        },

        /**
         * Collapse
         */
        collapse: function() {
            // Save a reference to the controller
            var self = this;

            // Show the teaser
            self.$teaser.show();

            // Hide the full text
            self.$full.hide();
        }
    });
}

runReadMore(window.FAB, window);
