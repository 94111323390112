function runMobileContentAccordion(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runMobileContentAccordion(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('MobileContentAccordion', {
        /** @var {Integer} mobileMenuAnimationTime */
        animationTime: 150,

        /** @var {Integer} desktopSwitchWidth */
        desktopSwitchWidth: 800,

        /** @var {Jquery} $items */
        $items: $(),

        /** @var {Jquery} $desktopTabs */
        $tabs: $(),

        /** @var {Jquery} $siteHeader */
        $siteHeader: $(),

        /** @var {Jquery} $htmlBody */
        $htmlBody: $(),

        /**
         * Modeled data for this controller
         * @var {Object} model
         */
        model: {
            activeIndex: 'int',
            mode: 'string'
        },

        /**
         * Events to automatically be bound
         * @var {Object} events
         */
        events: {
            'click .js-mobile-content-accordion-button': function(e) {
                // Save a reference to the controller
                var self = this;

                // Get the element
                var $el = $(e.currentTarget);

                // Set the active index
                self.model.set('activeIndex', $el.data('index'));
            }
        },

        /**
         * Init runs on controller construction
         */
        init: function() {
            // Save a reference to the controller
            var self = this;

            // Window jquery
            var $W = $(W);

            // Get the HTML body
            self.$htmlBody = $('html, body');

            // Set the site header
            self.$siteHeader = $('.js-site-header');

            // Index items
            self.indexItems();

            // Watch for changes to the activeIndex
            self.model.onChange('activeIndex', function(index) {
                self.activateItemAtIndex(index);
            });

            // Watch for changes to the desktop mode switch
            self.model.onChange('mode', function(mode) {
                if (mode === 'desktop') {
                    self.switchToDesktop();
                } else if (mode === 'mobile') {
                    self.switchToMobile();
                }
            });

            // Set initial mode
            self.model.set(
                'mode',
                W.innerWidth >= self.desktopSwitchWidth ?
                    'desktop' :
                    'mobile'
            );

            // Set mode based on window size
            $W.on('resize', function() {
                self.model.set(
                    'mode',
                    W.innerWidth >= self.desktopSwitchWidth ?
                        'desktop' :
                        'mobile'
                );
            });

            // Check for matching window anchor if on mobile
            if (self.model.get('mode') === 'mobile' && window.anchor) {
                // Get the anchor ID
                var anchor = window.anchor.split('#')[1];

                // Itereate through items
                self.$items.each(function() {
                    // Cast the element
                    var $el = $(this);

                    // Check for match
                    if ($el.data('accordionId') === anchor) {
                        // If we have match, set the active index
                        self.model.set('activeIndex', $el.data('index'));
                    }
                });
            }
        },

        /**
         * Index items
         */
        indexItems: function() {
            // Save a reference to the controller
            var self = this;

            // Set index variable
            var index = 0;

            // Set the active index
            self.model.set('activeIndex', 0);

            // Set items
            self.$items = self.$el.find('.js-mobile-content-accordion-content');

            // Set tabs
            self.$tabs = self.$el.find('.js-mobile-content-accordion-button');

            // Iterate through items and set index data
            self.$items.each(function() {
                var $el = $(this);

                $el.attr('data-index', index);

                $el.find('.js-tab-switcher').attr('data-index', index);

                index++;
            });

            // Reset index
            index = 0;

            // Iterate through tabs and set them
            self.$tabs.each(function() {
                var $el = $(this);

                $el.attr('data-index', index);

                index++;
            });
        },

        /**
         * Activate tab at index
         * @param {number} index
         */
        activateItemAtIndex: function(index) {
            // Save a reference to the controller
            var self = this;

            // Item
            var $item = self.$items.eq(index);

            // AnimationTime
            var animTime = self.animationTime;

            // Make sure this is mobile
            if (self.model.get('mode') !== 'mobile') {
                return;
            }

            // Close existing items
            self.$items.each(function() {
                // Cast the element
                var $el = $(this);

                // Check if the element is active
                if (! $el.is(':visible')) {
                    return;
                }

                // Deactivate the element
                $el.slideUp(
                    animTime,
                    'swing',
                    function() {
                        $el.removeClass($el.data('activeClass'));
                    }
                );
            });

            // Activate element
            $item.slideDown(animTime, 'swing');

            // Scroll to
            self.$htmlBody.animate({
                scrollTop: self.$el.offset().top - self.$siteHeader.innerHeight()
            }, animTime);
        },

        /**
         * Clear for desktop
         */
        switchToMobile: function() {
            var skip = true;

            this.$items.each(function() {
                // Cast the element
                var $el = $(this);

                if (skip === true) {
                    skip = false;
                    return;
                }

                // Remove inline styles
                $el.hide();
            });
        },

        /**
         * Clear for desktop
         */
        switchToDesktop: function() {
            var self = this;

            self.model.set('activeIndex', 0);

            self.$items.each(function() {
                // Cast the element
                var $el = $(this);

                // Remove inline styles
                $el.attr('style', null);
            });
        }
    });
}

runMobileContentAccordion(window.FAB, window);
